import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f472f0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview" }
const _hoisted_2 = { class: "button-download" }
const _hoisted_3 = { style: {"padding-top":"5px","box-sizing":"border-box"} }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  style: {"overflow-y":"auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_modal, {
      onCancel: _ctx.close,
      footer: null,
      visible: _ctx.previewVisible,
      width: "800px"
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDownload && _ctx.handleDownload(...args)))
          }, "下载")
        ], 512), [
          [_vShow, _ctx.showDownload]
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.fileType === 'pdf')
            ? (_openBlock(), _createElementBlock("iframe", {
                key: 0,
                id: "iframe",
                src: _ctx.base64code + '#toolbar=0',
                frameborder: "0",
                class: "pdf",
                width: "100%",
                height: "500px"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.fileType === 'jpg' || _ctx.fileType === 'png')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_a_image, {
                  width: 730,
                  src: _ctx.base64code
                }, null, 8, ["src"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["onCancel", "visible"])
  ]))
}