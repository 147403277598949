
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: String,
    showDownload: Boolean,
    previewVisible: {
      type: Boolean,
      requried: true,
      default: false,
    },
    fileType: {
      type: String,
      requried: true,
      default: "",
    },
    base64code: {
      type: String,
      requried: true,
      default: "",
    },
  },
  emits: ["close", "download"],
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };
    const handleDownload = () => {
      emit("download");
    };
    return {
      close,
      handleDownload,
    };
  },
});
