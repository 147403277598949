
import {
  computed,
  ref,
  reactive,
  defineComponent,
  onMounted,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { LeftOutlined } from "@ant-design/icons-vue";
import { useRouter, useRoute } from "vue-router";
import {
  dealerClaimDetail,
  supportView,
  invoiceView,
  licenseView,
  batchDownload,
} from "@/API/communication/appeal";
import PreviewModal from "../components/PreviewModal.vue";
import { b64toBlob } from "@/utils/claim/b64toBlob";

interface ProgramInfo {
  offerTypeId: string;
  offerTypeNameCn: string;
  offerTypeNameEn: string;
  programCode: string;
  programNameCn: string;
  programNameEn: string;
  siOffer: string;
}

interface VehicleInfo {
  customerName: string;
  firstSubmitDate: string;
  invoice: string;
  invoiceDate: string;
  invoicePrice: string;
  licenseDate: string;
  materialNo: string;
  model: string;
  modelYear: string;
  retailDate: string;
  retailEligibilityStatus: string;
  retailType: string;
  submissionDate: string;
  vehicleLicense: string;
  vin: string;
}

interface CheckResultInfo {
  checkResult: string;
  finalBonus: string;
  comments: string;
}

export default defineComponent({
  components: {
    LeftOutlined,
    PreviewModal,
  },
  setup() {
    const { t, locale } = useI18n();
    const isEn = computed(() => locale.value === "en");
    const fileType = ref("");
    const base64code = ref("");
    const previewVisible = ref<boolean>(false);
    const previewModalTitle = ref("");
    const showDownload = ref(false);
    let documentIdForModalDownload = "";
    const showPreview = (
      supportingDocId: string,
      supportingDocType: string,
      documentId: string,
      title: string,
      canDownload = false
    ) => {
      documentIdForModalDownload = documentId;
      previewModalTitle.value = title;
      showDownload.value = canDownload && !isEn.value;
      const params = {
        supportingDocId,
        supportingDocType,
      };
      supportView(params).then((res: any) => {
        if (res.ext === "jpg" || res.ext === "png") {
          base64code.value = "data:image/jpg;base64," + res.base64code;
        } else if (res.ext === "pdf") {
          base64code.value = URL.createObjectURL(
            b64toBlob(res.base64code, "application/pdf")
          );
        }
        fileType.value = res.ext;
        previewVisible.value = true;
      });
    };

    const closePreview = () => {
      previewModalTitle.value = "";
      showDownload.value = false;
      previewVisible.value = false;
    };

    const checkResultInfoColumns = [
      {
        title: t("dealerQueryDetail.checkResult"),
        width: 200,
        dataIndex: "checkResult",
      },
      {
        title: t("dealerQueryDetail.amount"),
        width: 200,
        dataIndex: "finalBonus",
      },
      {
        title: t("dealerQueryDetail.comment"),
        width: 500,
        dataIndex: "comments",
      },
    ];
    const communicationColumns = [
      {
        title: t("dealerQueryDetail.dealerCommunicationDeadline"),
        dataIndex: "deadline",
      },
      {
        title: t("dealerQueryDetail.roundClosing"),
        dataIndex: "roundClosing",
      },
      {
        title: t("dealerQueryDetail.lastCommunication"),
        dataIndex: "lastCommunication",
      },
      {
        title: t("dealerQueryDetail.comment"),
        dataIndex: "comments",
      },
    ];
    const operationRecordColumns = [
      {
        title: t("dealerQueryDetail.action"),
        dataIndex: "action",
      },
      {
        title: t("dealerQueryDetail.operationUser"),
        dataIndex: "operateBy",
      },
      {
        title: t("dealerQueryDetail.operationTime"),
        dataIndex: "operateAt",
      },
      {
        title: t("dealerQueryDetail.comment"),
        dataIndex: "comments",
      },
    ];

    const route = useRoute();
    const router = useRouter();
    const goBack = () => {
      router.go(-1);
    };

    const programInfo = ref<ProgramInfo>({
      offerTypeId: "",
      offerTypeNameCn: "",
      offerTypeNameEn: "",
      programCode: "",
      programNameCn: "",
      programNameEn: "",
      siOffer: "",
    });
    const vehicleInfo = ref<VehicleInfo>({
      customerName: "",
      firstSubmitDate: "",
      invoice: "",
      invoiceDate: "",
      invoicePrice: "",
      licenseDate: "",
      materialNo: "",
      model: "",
      modelYear: "",
      retailDate: "",
      retailEligibilityStatus: "",
      retailType: "",
      submissionDate: "",
      vehicleLicense: "",
      vin: "",
    });
    const checkResultInfo = ref<CheckResultInfo[]>([]);
    const store = useStore();
    const showPreview2 = (type: string, path: any) => {
      if (path === "" || path === null || path === undefined) return;
      const params = {
        vinNo: vehicleInfo.value.vin,
        fileName: "",
        dealerCode: route.params.dealerCode as string,
      };
      let getView: (params: any) => Promise<any>;
      if (type === "invoice") {
        getView = invoiceView;
      } else {
        getView = licenseView;
      }
      getView(params).then((res: any) => {
        const docType = (res.docType as string).toLowerCase();
        if (docType === "jpg" || docType === "png") {
          base64code.value = res.downloadUrl;
        } else if (docType === "pdf") {
          base64code.value = URL.createObjectURL(
            b64toBlob(res.base64, "application/pdf")
          );
        }
        previewVisible.value = true;
        fileType.value = docType;
      });
    };
    const communicationInfo = ref([]);
    const operationRecordInfo = ref([]);

    //Document data数据
    const documentSourceData = ref<any[]>([]);
    //Document data 表头
    const documentColumns = ref<any[]>([]);
    //Document slot
    const documentSlot = ref<any[]>([]);
    const documentTitleSlot = ref<any[]>([]);
    const columnTitle = ref<any>([]);
    //Document 表格宽度
    const documentTableWidth = ref(950);

    const handleDocument = (data: any) => {
      if (data.length === 0) {
        return;
      }
      const columnsArray = []; //表头
      const dataSourceColumnsArray = []; //table数据
      const slotArray = []; //插槽名称
      const titleSlotArray = []; //插槽名称
      let tableWidth = 0;
      columnTitle.value = data[0].slice(1);
      //生成表头,和数据对象的key, i 表示列
      for (let i = 0; i < data[0].length; i++) {
        if (i === 0) {
          columnsArray.push({
            title: t("dealerQueryDetail.type"),
            dataIndex: "name",
            key: "name",
          });
        } else {
          columnsArray.push({
            // title: data[0][i],
            dataIndex: "rep" + i,
            key: "rep" + i,
            slots: { customRender: "rep" + i, title: "title" + i },
          });
          slotArray.push("rep" + i);
          titleSlotArray.push("title" + i);
          tableWidth += 300;
        }
      }
      //生成数据
      for (let i = 1; i < data.length; i++) {
        const onerow = data[i]; //要处理的每一行数据

        const rowData = {}; //最终生成的行对象
        rowData["name"] = onerow[0];
        for (let j = 1; j < onerow.length; j++) {
          if (onerow[j] !== "") {
            rowData[columnsArray[j].dataIndex] = (
              onerow[j] as any
            ).supportingDocReName;
            rowData[`${columnsArray[j].dataIndex}_supportingDocId`] = (
              onerow[j] as any
            ).supportingDocId;
            rowData[`${columnsArray[j].dataIndex}_documentId`] = (
              onerow[j] as any
            ).documentId;
            rowData[`${columnsArray[j].dataIndex}_supportDocType`] = (
              onerow[j] as any
            ).supportDocType;
            rowData[`${columnsArray[j].dataIndex}_check`] = false;
          } else {
            rowData[columnsArray[j].dataIndex] = "";
          }
        }
        dataSourceColumnsArray.push(rowData);
      }
      documentColumns.value = columnsArray;
      documentSourceData.value = dataSourceColumnsArray;
      documentSlot.value = slotArray;
      documentTitleSlot.value = titleSlotArray;
      if (tableWidth > documentTableWidth.value) {
        documentTableWidth.value = tableWidth;
      }
    };

    // 计算属性数组来定义 表头里的 checkbox
    const columnsCheckComputed = ref<any>([]);
    const computeColumnsCheck = () => {
      const documentData = documentSourceData.value;
      const len = documentData.length;
      return documentTitleSlot.value.map((colTitle: any, col: number) => {
        return computed({
          get() {
            const checkList = [];
            for (let row = 0; row < len; row++) {
              if (documentData[row][`rep${col + 1}`]) {
                checkList.push(documentData[row][`rep${col + 1}_check`]);
              }
            }
            return checkList.length && checkList.every(Boolean);
          },
          set(newVal) {
            for (let row = 0; row < len; row++) {
              if (documentData[row][`rep${col + 1}`]) {
                documentData[row][`rep${col + 1}_check`] = newVal;
              }
            }
          },
        });
      });
    };

    const genName = (
      path: string,
      prefix: string,
      vinNo: string,
      ext: string
    ) => {
      if (!path) return "";
      const suffix = ext.toLowerCase();
      return `${prefix}_${vinNo}.${suffix}`;
    };

    const claimId = route.params.claimId as string;
    onMounted(() => {
      dealerClaimDetail(claimId).then((res: any) => {
        programInfo.value = res.programInfo || {};
        vehicleInfo.value = res.vehicleInfo || {};
        const tempCheckResultInfo = res.checkResultInfo || [];
        tempCheckResultInfo.forEach((item: any) => {
          item.comments = item.comments?.replace(/\\n/g, " ");
        });
        checkResultInfo.value = tempCheckResultInfo;
        communicationInfo.value = res.communicationInfo;
        operationRecordInfo.value = res.operationRecordInfo;
        handleDocument(res.claimDetailDocument);
        columnsCheckComputed.value = computeColumnsCheck();
      });
    });

    const retailEligibilityStatusMap = {
      "0000": "无",
      "1111": "系统检查中",
      "1112": "待审核",
      "1113": "修改待审核",
      "2222": "有效",
      "3333": "无效",
    };

    const getDownloadDocList = () => {
      const docList: any = [];
      const documentData = documentSourceData.value;
      const len = documentData.length;
      documentTitleSlot.value.forEach((colTitle: any, col: number) => {
        for (let row = 0; row < len; row++) {
          if (documentData[row][`rep${col + 1}_check`]) {
            docList.push(documentData[row][`rep${col + 1}_documentId`]);
          }
        }
      });
      return docList;
    };

    const download = (params: any) => {
      batchDownload(claimId, params).then((res: any) => {
        if (res !== null) {
          const downloadElement = document.createElement("a");
          downloadElement.href = res;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
        }
      });
    };

    const handleBatchDownload = () => {
      //获取docList
      const params = getDownloadDocList();
      download(params);
    };

    const handleModalDownload = () => {
      if (documentIdForModalDownload) {
        download([documentIdForModalDownload]);
      }
    };

    return {
      previewVisible,
      closePreview,
      showPreview,
      showPreview2,
      fileType,
      base64code,
      programInfo,
      vehicleInfo,
      checkResultInfoColumns,
      checkResultInfo,
      communicationColumns,
      communicationInfo,
      operationRecordColumns,
      operationRecordInfo,
      goBack,
      handleDocument,
      documentColumns,
      documentSourceData,
      documentSlot,
      documentTableWidth,
      retailEligibilityStatusMap,
      genName,
      documentTitleSlot,
      columnTitle,
      columnsCheckComputed,
      handleBatchDownload,
      previewModalTitle,
      showDownload,
      handleModalDownload,
      isEn,
    };
  },
});
